.service-status-pending {
  border:2px solid #f0b215;
  box-shadow: 0 0 0 0 rgb(240, 178, 21);
  transform: scale(1);
  animation: pulse 2s infinite;
}

.service-status-resolved , .mot-pass{
  background: #8fdd95;
}


.mot-fail{
  background: rgba(255, 101, 101, 0.16);
}

//Animation
@keyframes pulse {
  0% {
    //transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(240, 178, 21);
  }

  70% {
    //transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    //transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}



.active-black{
  @apply bg-black text-white;
}
