@import "tailwindcss/base.css";
@import "tailwindcss/utilities.css";
@import "tailwindcss/components.css";
@import "shared-styles/helpers";
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* angular-cli file: src/styles.css */
@import "../node_modules/angular-calendar/css/angular-calendar.css";

*{
  font-family: 'Poppins', sans-serif;
}


.link-active-primary{
  @apply border-2;
}

.pending-card-border{
  @apply border-yellow-400;
}

.in-process-card-border {
  @apply border-primary;
}

.resolved-card-border {
  @apply border-green-500;
}

.from-yesterday-card-border {
  @apply border-red-500 border-4;
}
